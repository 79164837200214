<template>
  <div>
    <TitleShow :titleWithWorkCard="true" text="MG 動畫作品" />
    <Preview
      :previewData="previewData"
      :dialog="dialog"
      @handleClose="handleClose"
    />
    <WorkCard :items="currentData" @handlePreview="handlePreview" />
    <transition name="fade" mode="out-in">
      <div v-if="handleLoading" :class="fadeIn" class="text-center padding-tb">
        <img :src="require('@/assets/Rolling-1s-30px-blank.svg')" />
      </div>
    </transition>
    <div
      v-if="handleNoDataShow"
      class="text-center padding-tb text-xs text-grey"
    >
      目前沒有更多作品可以顯示
    </div>
  </div>
</template>

<script>
import mainCommon from "@/utils/mainCommon";
import configCommon from "@/utils/configCommon";
import Preview from "@/components/Preview";
import WorkCard from "@/components/WorkCard";

export default {
  mixins: [mainCommon],
  components: {
    Preview,
    WorkCard,
  },
  data() {
    return {
      dialog: false,
      previewData: null,
      width: configCommon.waterfallWidth,
      height: configCommon.waterfallHeight,
      currentData: [],
      handleNoDataShow: false,
      handleLoading: false,
      page: 1,
      pageSize: configCommon.workCardPageSize,
    };
  },
  computed: {
    mgItems() {
      return this.$store.state.mgWork.mgItems;
    },
    paramId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.handleParamId();
    this.handleLoadData(false);
    this.HandleWindowScrollListener(true, this.handleScroll);
  },
  destroyed() {
    this.HandleWindowScrollListener(false, this.handleScroll);
  },
  methods: {
    handleParamId() {
      if (!this.paramId) return;
      this.mgItems.forEach((item) => {
        if (
          encodeURIComponent(this.paramId) === encodeURIComponent(item.title)
        ) {
          this.dialog = true;
          this.previewData = item;
        }
      });
    },
    handleLoadData(isScroll) {
      this.handleLoading = true;
      if (isScroll && this.mgItems.length === this.currentData.length) {
        this.handleLoading = false;
        this.handleNoDataShow = true;
        return;
      }
      if (isScroll) this.page += 1;
      setTimeout(() => {
        this.handleLoading = false;
        this.currentData = this.mgItems.slice(0, this.page * this.pageSize);
      }, configCommon.loadingDataTimeout);
    },
    handleScroll() {
      if (
        document.documentElement.scrollTop + window.innerHeight >=
        document.documentElement.offsetHeight -
          configCommon.workCardScrollBottomHeight
      )
        this.handleLoadData(true);
    },
    handlePreview(item) {
      this.dialog = true;
      this.previewData = item;
      let route = `${item.route}/${encodeURIComponent(item.title)}`;
      this.HandleRouteHistory(route, "preview", item.title);
      this.HandleRouteState(route);
    },
    handleClose(item) {
      this.dialog = false;
      this.previewData = null;
      this.HandleRouteState(item.route);
    },
  },
};
</script>